<script>
import { login } from 'loginApp/api/logins.js';
import KogLoginErrorBox from 'loginApp/components/kog-login-error-box.vue';
import LoginWarningBox from 'loginApp/components/login-warning-box.vue';
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';
import googleLogo from 'sharedApp/img/google-signin-logo.png';
import managebacLogo from 'sharedApp/img/managebac-signin-logo.png';
import ClassicLoginLocalStorage, {
  LOGIN_METHOD_GOOGLE,
  LOGIN_METHOD_MANAGEBAC,
  LOGIN_METHOD_MANAGEBAC_CHINA,
  LOGIN_METHOD_PASSWORD,
} from 'sharedApp/utils/login-local-storage.js';

const googleLoginUrl = '/accounts/login/google/';
const manageBacGlobalLoginUrl = '/accounts/login/managebac/?region=global';
const manageBacChinaLoginUrl = '/accounts/login/managebac/?region=china';

const loginAppTrackingStorage = new ClassicLoginLocalStorage(localStorage);

export default {
  name: 'LoginPageContainer',
  components: {
    KogItemList,
    KogLoginErrorBox,
    LoginWarningBox,
  },
  data: () => ({
    email: '',
    password: '',
    passwordFieldType: 'password',
    googleButtonLogo: googleLogo,
    managebacButtonLogo: managebacLogo,
    isLoginError: false,
    isLoginInProgress: false,
    warningCode: '',
  }),
  computed: {
    isLoginFormInValid() {
      return this.email === '' || this.password === '';
    },
    isLoginFormDisabled() {
      return this.isLoginFormInValid || this.isLoginInProgress;
    },
    isPasswordShown() {
      return this.passwordFieldType !== 'password';
    },
  },
  mounted() {
    const { error, next } = { ...this.$route.query };
    if (!error && next) {
      this.warningCode = 'unauthenticated_user';
    } else if (error) {
      this.warningCode = error;
      this.clearErrorQueryParam();
    }
    loginAppTrackingStorage.removeFlag();
  },
  methods: {
    async loginWithEmailPassword() {
      this.isLoginInProgress = true;

      loginAppTrackingStorage.setFlag(LOGIN_METHOD_PASSWORD);

      const nextUrl = this.$route.query.next;
      const response = await login(this.email, this.password, nextUrl).catch(() => {
        this.password = '';
        this.isLoginError = true;
        this.isLoginInProgress = false;
      });

      if (response && response.status === 200) {
        const isNextUrlSafe = response.data.is_next_url_safe === true;
        window.location = isNextUrlSafe ? nextUrl : '/';
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    loginWithGoogle() {
      this.isLoginInProgress = true;
      loginAppTrackingStorage.setFlag(LOGIN_METHOD_GOOGLE);
      window.location = googleLoginUrl;
    },
    loginWithManageBacGlobal() {
      this.isLoginInProgress = true;
      loginAppTrackingStorage.setFlag(LOGIN_METHOD_MANAGEBAC);
      window.location = manageBacGlobalLoginUrl;
    },
    loginWithManageBacChina() {
      this.isLoginInProgress = true;
      loginAppTrackingStorage.setFlag(LOGIN_METHOD_MANAGEBAC_CHINA);
      window.location = manageBacChinaLoginUrl;
    },
    clearErrorQueryParam() {
      const queryParams = { ...this.$route.query };
      delete queryParams.error;
      this.$router.replace({ query: queryParams });
    },
  },
};
</script>

<template>
  <div class="flexContainer flexContainer-center padd-top-xxl">
    <div class="LoginPageContainer">
      <login-warning-box
        v-if="warningCode"
        :warning-code="warningCode"
      />

      <div class="LoginCard padd-top-xl padd-left-xxl padd-right-xxl padd-bottom-xl">
        <h1 class="heading-s sm:heading-m margin-bottom-l"> Welcome back to Kognity! </h1>

        <form
          id="login-form"
          @submit.prevent="loginWithEmailPassword()"
        >
          <div
            id="loginErrorBox"
            role="alert"
          >
            <kog-login-error-box v-if="isLoginError">
              Sorry, either your email or password is incorrect. We can help you
              <router-link to="password-reset"> reset your password </router-link>
            </kog-login-error-box>
          </div>
          <div class="margin-bottom-m">
            <label
              for="email"
              class="text-normal margin-bottom-xxs"
            >
              Email
            </label>
            <input
              id="email"
              v-model="email"
              class="KogFormInput"
              type="email"
              autocomplete="email"
              name="email"
              placeholder="Enter your email"
              required
            />
          </div>
          <div class="margin-bottom-l">
            <label
              for="password"
              class="text-normal margin-bottom-xxs"
            >
              Password
            </label>
            <div class="absoluteContainer">
              <input
                id="password"
                v-model="password"
                class="KogFormInput margin-bottom-xxs"
                :type="passwordFieldType"
                autocomplete="current-password"
                name="password"
                placeholder="Enter your password"
                required
              />
              <button
                type="button"
                :aria-label="isPasswordShown ? 'Hide password' : 'Show password'"
                class="KogButtonLegacy KogButtonLegacy--subtleLink KogButtonLegacy--s LoginCard-showPasswordButton"
                @click="togglePasswordVisibility"
              >
                <span class="flexContainer flexContainer-flexEnd padd-right-s">
                  <span
                    class="flexContainer flexContainer-center text-small muted margin-right-xxs"
                  >
                    <span v-if="isPasswordShown">Hide</span>
                    <span v-if="!isPasswordShown">Show</span>
                  </span>
                  <span class="KogIcon-dark KogIcon--m">
                    <i
                      v-if="isPasswordShown"
                      class="far fa-eye-slash KogFontAwesomeIcon--m"
                    />
                    <i
                      v-if="!isPasswordShown"
                      class="far fa-eye KogFontAwesomeIcon-m"
                    />
                  </span>
                </span>
              </button>
              <router-link
                to="password-reset"
                class="KogButtonLegacy--link"
              >
                Forgot password?
              </router-link>
              <button
                class="LoginCard-loginButton KogButtonLegacy KogButtonLegacy--primary margin-top-l"
                :disabled="isLoginFormDisabled"
              >
                <span
                  id="login-form-submit-button"
                  class="LoginCard-buttonText"
                >
                  <i
                    v-if="isLoginInProgress"
                    class="fa fa-spinner fa-spin fa-1x fa-fw"
                  />
                  <span v-else> Log in </span>
                </span>
              </button>
            </div>
          </div>
        </form>
        <h2 class="LoginCard-sectionBreak margin-top-xl margin-bottom-xl divider-bottom">
          <span class="LoginCard-sectionBreak--text muted"> or log in with </span>
        </h2>
        <kog-item-list
          role="list"
          :is-handling-tab-index="true"
        >
          <div
            role="listitem"
            class="margin-bottom-m"
          >
            <button
              :disabled="isLoginInProgress"
              aria-label="Log in with Google"
              class="LoginCard-loginButton KogButtonLegacy"
              @click="loginWithGoogle()"
            >
              <img
                :src="googleButtonLogo"
                alt=""
                class="LoginCard-buttonLogo"
              />
              <span class="LoginCard-buttonText margin-right-xxl"> Google </span>
            </button>
          </div>
          <div
            role="listitem"
            class="margin-bottom-m"
          >
            <button
              :disabled="isLoginInProgress"
              aria-label="Log in with ManageBac"
              class="LoginCard-loginButton KogButtonLegacy"
              @click="loginWithManageBacGlobal()"
            >
              <img
                :src="managebacButtonLogo"
                class="LoginCard-buttonLogo"
                alt=""
              />
              <span class="LoginCard-buttonText margin-right-xxl"> Managebac </span>
            </button>
          </div>
          <div role="listitem">
            <button
              :disabled="isLoginInProgress"
              aria-label="Log in with ManageBac China"
              class="LoginCard-loginButton KogButtonLegacy"
              @click="loginWithManageBacChina()"
            >
              <img
                :src="managebacButtonLogo"
                class="LoginCard-buttonLogo"
                alt=""
              />
              <span class="LoginCard-buttonText margin-right-xxl"> Managebac China </span>
            </button>
          </div>
        </kog-item-list>
      </div>
      <div class="width-full flexContainer flexContainer-center margin-top-m">
        <router-link
          class="white-link"
          to="/"
          >Use New Login</router-link
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.LoginPageContainer {
  max-width: 390px;
}

.LoginCard {
  width: 100%;

  font-family: var(--kog-satoshi);
  color: var(--kogPlatformGray018);

  background-color: var(--kogPlatformWhite);
  border: 1px solid var(--kogPlatformGray090);
  border-radius: var(--space-xs);
}

@media screen and (min-width: 576px) {
  .LoginCard {
    width: auto;
    min-width: 390px;
  }

  .sm\:heading-m {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
}

@media screen and (min-width: 768px) {
  .md\:flexChild-flexCenter {
    align-self: center;
  }
}

.LoginCard-showPasswordButton {
  position: absolute;
  z-index: 2;
  top: 4%;
  right: 0;

  display: inline;

  padding: 0;
}

.LoginCard-loginButton {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0;

  text-align: center;
}

.LoginCard-buttonLogo {
  width: 38px;
  height: 100%;
}

.LoginCard-buttonText {
  flex-grow: 1;
  text-align: center;
}

.LoginCard-sectionBreak {
  width: 100%;
  line-height: 0.1em;
  text-align: center;
}

.LoginCard-sectionBreak--text {
  padding: 0 var(--space-m);
  font-size: var(--kog-font-size-default);
  font-weight: normal;
  background-color: var(--kogPlatformWhite);
}

:focus {
  border-color: var(--kogPlatformBlueDarken20);
}

.white-link {
  color: var(--kogPlatformGray098);
}
</style>
