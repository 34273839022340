<script>
export default {
  name: 'GenericWarningBox',
  props: {
    mode: {
      type: String,
      default: 'warning',
      validator: mode => ['info', 'warning', 'error', 'success'].includes(mode),
    },
    hasPaddBottom: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cssClassMode() {
      return `WarningBox-${this.mode}`;
    },
  },
};
</script>

<template>
  <div :class="{ 'padd-bottom-xxl': hasPaddBottom }">
    <div
      class="WarningBox"
      :class="cssClassMode"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped>
.WarningBox {
  width: 100%;
  padding: var(--space-m) var(--space-l);
  font-family: var(--kog-satoshi);
  border-radius: var(--space-xs);
}

@media screen and (min-width: 576px) {
  .WarningBox {
    width: auto;
    min-width: min(390px, 100%);
  }
}

.WarningBox-info {
  color: var(--kogPlatformGray018);
  background-color: var(--kog-colors-aubergine-100);
  border: 1px solid var(--kog-border-brand-900);
}

.WarningBox-warning {
  color: var(--kogPlatformGray018);
  background-color: var(--kogPlatformYellowLighten20);
  border: 1px solid var(--kogPlatformYellowBase);
}

.WarningBox-success {
  color: var(--kogPlatformGray018);
  background-color: var(--kogPlatformGreenLighten20);
  border: 1px solid var(--kogPlatformGreenBase);
}

.WarningBox-error {
  color: var(--kogPlatformRedDarken40);
  background-color: var(--kogPlatformRedLighten20);
  border: 1px solid var(--kogPlatformRedLighten10);
}

.WarningBox-error a {
  color: var(--kogPlatformRedDarken40);
  text-decoration: underline;
}
</style>
