<script>
import { VTooltip } from 'floating-vue';

export default {
  name: 'KogCheckbox',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    /**
     * Can be *true*, *false* or *'mixed'*
     */
    isChecked: {
      validator: prop => typeof prop === 'boolean' || prop === 'mixed',
      default: false,
    },
    /**
     * If disabled, the checkbox is not clickable or focusable
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The label should always be specified, for accessibility purposes.
     * If you don't want it to be visible, use isLabelHidden: true
     */
    label: {
      type: String,
      default: '',
    },
    isLabelHidden: {
      type: Boolean,
      default: false,
    },
    /**
     * Accessibility label to use to convey information in addition to the label.
     * If no `ariaLabel` property is provided, it will default to the `label` value.
     */
    ariaLabel: {
      type: String,
      default: '',
    },
    /**
     * Set this to true if you want the label to appear on the left side of the checkbox.
     */
    isLabelOnLeft: {
      type: Boolean,
      default: false,
    },
    /**
     * Specifies content of optional tooltip that shows on checkbox hover.
     * No tooltip shown if this property is not provided.
     */
    tooltipText: {
      type: String,
      default: '',
    },
  },
  emits: ['toggled', 'update:isChecked'],
  computed: {
    checkboxClasses() {
      const cssClasses = [];
      if (this.isLabelOnLeft) {
        cssClasses.push('KogCheckbox-leftLabel');
      }

      if (this.isDisabled) {
        cssClasses.push('KogCheckbox-disabled');
      } else {
        cssClasses.push('KogCheckbox-enabled');
      }

      if (this.isChecked === true) {
        cssClasses.push('is-checked');
      } else if (this.isChecked === 'mixed') {
        cssClasses.push('is-mixed');
      } else {
        cssClasses.push('is-unchecked');
      }

      return cssClasses;
    },
    checkIconPath() {
      if (this.isChecked === true) {
        return 'M4 0C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79086 12.2091 0 10 0H4ZM11.7809 4.62467C12.1259 4.19341 12.056 3.56412 11.6247 3.21911C11.1934 2.8741 10.5641 2.94402 10.2191 3.37528L6.09484 8.53065L3.68394 6.27044C3.28103 5.89271 2.6482 5.91312 2.27047 6.31603C1.89274 6.71895 1.91315 7.35178 2.31606 7.72951L5.51606 10.7295C5.71948 10.9202 5.99328 11.0173 6.27139 10.9974C6.54951 10.9775 6.80669 10.8424 6.98087 10.6247L11.7809 4.62467Z';
      }
      if (this.isChecked === 'mixed') {
        return 'M4 0C1.79086 0 0 1.79086 0 4V10C0 12.2091 1.79086 14 4 14H10C12.2091 14 14 12.2091 14 10V4C14 1.79086 12.2091 0 10 0H4ZM3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8L11 8C11.5523 8 12 7.55229 12 7C12 6.44772 11.5523 6 11 6L3 6Z';
      }

      return '';
    },
  },
  methods: {
    toggle() {
      if (!this.isDisabled) {
        let newIsChecked;
        if (this.isChecked !== 'mixed') {
          newIsChecked = !this.isChecked;
        } else {
          newIsChecked = true;
        }
        /**
         * Emits new value after being toggled
         *
         * @event toggled
         * @type {boolean}
         * @property {boolean} newIsChecked - new value for the checkbox
         */
        this.$emit('update:isChecked', newIsChecked);
        this.$emit('toggled', newIsChecked);
      }
    },
  },
};
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
  <span
    v-tooltip="{
      theme: 'kog-tooltip',
      content: tooltipText,
      autoHide: false,
    }"
    :class="checkboxClasses"
    class="KogCheckbox"
    @click.stop="toggle()"
    @keydown.space.prevent="toggle()"
  >
    <span
      v-if="label && !isLabelHidden && isLabelOnLeft"
      aria-hidden="true"
      class="KogCheckbox-label"
    >
      {{ label }}
    </span>
    <span class="KogCheckbox-iconOuterContainer">
      <span
        class="KogCheckbox-iconInnerContainer"
        role="checkbox"
        :aria-checked="isChecked"
        :aria-label="ariaLabel || label"
        :tabindex="isDisabled ? null : 0"
      >
        <svg
          v-if="isChecked === true || isChecked === 'mixed'"
          class="KogCheckbox-icon"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            class="KogCheckbox-iconPath"
            fill-rule="evenodd"
            clip-rule="evenodd"
            :d="checkIconPath"
          />
        </svg>
      </span>
    </span>
    <span
      v-if="label && !isLabelHidden && !isLabelOnLeft"
      aria-hidden="true"
      class="KogCheckbox-label"
    >
      {{ label }}
    </span>
  </span>
</template>

<style scoped>
.KogCheckbox {
  overflow-y: visible;
  display: inline-flex;
  align-items: center;

  width: fit-content;
  min-height: 24px;
  max-height: 24px;
}

.KogCheckbox-label {
  line-height: 24px;
  color: var(--kog-checkbox-label-color);
}

.KogCheckbox-leftLabel {
  text-align: right;
}

.KogCheckbox-iconOuterContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;

  border-radius: 50%;
}

.KogCheckbox-iconInnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 14px;
  height: 14px;

  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
}

.KogCheckbox-disabled .KogCheckbox-iconInnerContainer {
  border-color: var(--kog-checkbox-border-disabled);
}

.KogCheckbox-disabled .KogCheckbox-iconPath {
  fill: var(--kog-checkbox-icon-color-disabled);
}

.KogCheckbox-disabled .KogCheckbox-label {
  color: var(--kog-checkbox-label-disabled);
}

.KogCheckbox-enabled .KogCheckbox-iconOuterContainer {
  cursor: pointer;
}

.KogCheckbox-enabled .KogCheckbox-iconOuterContainer:hover {
  background-color: var(--kog-checkbox-hover);
}

.KogCheckbox-enabled .KogCheckbox-iconInnerContainer {
  border-color: var(--kog-checkbox-checked-border);
}

.KogCheckbox.is-checked .KogCheckbox-iconInnerContainer,
.KogCheckbox.is-mixed .KogCheckbox-iconInnerContainer {
  border: none;
}

.KogCheckbox.is-unchecked:not(.KogCheckbox-disabled) .KogCheckbox-iconInnerContainer {
  border-color: var(--kog-checkbox-unchecked-border);
}

.KogCheckbox-enabled .KogCheckbox-iconPath {
  fill: var(--kog-checkbox-fill);
}
</style>
