<script setup lang="ts">
import KogCheckbox from 'sharedApp/components/base/checkbox/kog-checkbox.vue';

type Option = {
  value: string;
  label: string;
};

const props = defineProps<{
  options: Option[];
  modelValue: string[];
  label: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [string[]];
}>();

const onOptionToggled = (value: string, isChecked: boolean) => {
  const newSelectedOptions = isChecked
    ? props.modelValue.concat(value)
    : props.modelValue.filter(selectedValue => selectedValue !== value);
  emit('update:modelValue', newSelectedOptions);
};
</script>

<template>
  <fieldset
    class="flexContainer flexContainer-column"
    :disabled="disabled"
  >
    <legend class="heading-xxs border-none margin-bottom-xxs">
      {{ label }}
    </legend>
    <kog-checkbox
      v-for="(option, index) in options"
      :key="option.value"
      :is-disabled="disabled"
      :class="index === 0 ? '' : 'margin-top-xxs'"
      :label="option.label"
      :is-checked="modelValue?.includes(option.value)"
      @update:is-checked="(value: boolean) => onOptionToggled(option.value, value)"
    />
  </fieldset>
</template>
