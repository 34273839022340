<script>
import isNil from 'lodash/isNil.js';
import sample from 'lodash/sample.js';

import KogNewNavbar from 'sharedApp/components/core/kog-new-navbar.vue';
import logoUrl from 'sharedApp/img/kognity-logo-text-green.svg';

const ASSETS = {
  APP_BASE_URL: '/',
  KOGNITY_LOGO_URL: logoUrl,
};

export default {
  name: 'DefaultLayout',
  components: {
    KogNewNavbar,
  },
  data() {
    return {
      ASSETS,
      appBackgroundClasses: [
        'app--backgroundA',
        'app--backgroundB',
        'app--backgroundC',
        'app--backgroundD',
      ],
      transitionName: null,
      pageNavigationMessage: '',
    };
  },
  computed: {
    showingInvitationPage() {
      return this.$route.name === 'accept-invitation';
    },
    loginAppBackgroundClass() {
      if (this.showingInvitationPage) {
        return 'app-backgroundWhite';
      }

      if (isNil(window.sessionStorage)) {
        return sample(this.appBackgroundClasses);
      }
      if (!sessionStorage.loginAppBackgroundClass) {
        sessionStorage.loginAppBackgroundClass = sample(this.appBackgroundClasses);
      }
      return sessionStorage.loginAppBackgroundClass;
    },
  },
  watch: {
    $route(to, from) {
      if (from.path === '/' && to.path === '/password-reset') {
        this.transitionName = 'slideRight';
        this.pageNavigationMessage = 'Entered reset password page';
      } else if (to.path === '/' && from.path === '/password-reset') {
        this.transitionName = 'slideLeft';
        this.pageNavigationMessage = 'Returned from reset password page';
      }
    },
  },
};
</script>

<template>
  <div
    class="app"
    :class="loginAppBackgroundClass"
  >
    <kog-new-navbar :class="{ navbar: !showingInvitationPage }">
      <template #leftCornerSlot>
        <a
          class="brand"
          :href="showingInvitationPage ? undefined : ASSETS.APP_BASE_URL"
        >
          <img
            :src="ASSETS.KOGNITY_LOGO_URL"
            alt="Kognity home"
          />
        </a>
      </template>
    </kog-new-navbar>
    <main class="main">
      <div
        role="alert"
        class="screenreader-only"
      >
        {{ pageNavigationMessage }}
      </div>
      <transition
        mode="out-in"
        :name="transitionName"
      >
        <router-view />
      </transition>
    </main>

    <footer class="footer">
      <div class="footer-main">
        <a
          class="footer-link"
          href="https://www.kognity.com/"
          title="Kognity"
        >
          <u>www.kognity.com</u>
        </a>
      </div>
      <div class="footer-right">
        <a
          class="footer-link padd-right-xl"
          href="/cookie-policy/"
        >
          <u>Cookies</u>
        </a>
        <a
          class="footer-link padd-right-m"
          href="/terms/"
        >
          <u>Terms of use</u>
        </a>
        <span class="kognity-copyright text-center">© Kognity </span>
      </div>
    </footer>
  </div>
</template>

<style scoped>
.slideRight-enter-active,
.slideRight-leave-active,
.slideLeft-enter-active,
.slideLeft-leave-active {
  transition: all 0.2s;
}

.slideRight-enter-from,
.slideLeft-leave-to {
  transform: translateX(10%);
  opacity: 0;
}

.slideRight-leave-to,
.slideLeft-enter-from {
  transform: translateX(-10%);
  opacity: 0;
}

.slideRight-enter-to,
.slideRight-leave-from,
.slideLeft-enter-to,
.slideLeft-leave-from {
  transform: translateX(0%);
  opacity: 1;
}

.app {
  min-height: 100vh;
  background-color: var(--kogPlatformGray098);
  background-clip: content-box;
  background-size: 100% 100%;
}

.app--backgroundA {
  background: url('~sharedApp/img/login/kognity-bg-login-a.svg');
}

.app--backgroundB {
  background: url('~sharedApp/img/login/kognity-bg-login-b.svg');
}

.app--backgroundC {
  background: url('~sharedApp/img/login/kognity-bg-login-c.svg');
}

.app--backgroundD {
  background: url('~sharedApp/img/login/kognity-bg-login-d.svg');
}

.app-backgroundWhite {
  background-color: var(--kogPlatformWhite);
}

.main {
  padding: calc(4.5 * var(--space-unit)) var(--space-l);
  padding-bottom: 156px;
}

.navbar {
  background: none;
  box-shadow: none;
}

.brand {
  display: inline-block;
}

.brand img {
  max-height: 100%;
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  display: grid;
  grid-template-areas:
    'footer-left'
    'footer-main'
    'footer-right';
  grid-template-columns: 1fr;

  padding-bottom: 16px;
}

.footer-link {
  color: var(--kogPlatformGray098);
}

.footer-main {
  grid-area: footer-main;
  justify-self: center;
}

.footer-right {
  display: flex;
  grid-area: footer-right;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
}

.kognity-copyright {
  padding-top: 4px;
  color: var(--kogPlatformGray098);
}

@media screen and (max-width: 768px) {
  .app--backgroundA {
    background: url('~sharedApp/img/login/kognity-bg-login-a-slim.svg');
  }

  .app--backgroundB {
    background: url('~sharedApp/img/login/kognity-bg-login-b-slim.svg');
  }

  .app--backgroundC {
    background: url('~sharedApp/img/login/kognity-bg-login-c-slim.svg');
  }

  .app--backgroundD {
    background: url('~sharedApp/img/login/kognity-bg-login-d-slim.svg');
  }
}

@media screen and (min-width: 841px) {
  .main {
    padding-bottom: 80px;
  }

  .footer {
    grid-template-areas: 'footer-left footer-main footer-right';
    grid-template-columns: 2fr 1fr 2fr;
  }

  .footer-right {
    grid-area: footer-right;
    flex-direction: row;
    align-items: center;
    justify-self: end;

    padding-right: 32px;
  }

  .kognity-copyright {
    padding-top: 0;
    padding-left: 16px;
  }
}
</style>
