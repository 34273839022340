<script>
import { nextTick } from 'vue';

import { getAuthenticationProviders, login } from 'loginApp/api/logins.js';
import KogLoginErrorBox from 'loginApp/components/kog-login-error-box.vue';
import LoginWarningBox from 'loginApp/components/login-warning-box.vue';
import SsoLogin from 'loginApp/pages/login/components/sso-login.vue';
import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogInput from 'sharedApp/components/base/input/kog-input.vue';
import ClassicLoginLocalStorage from 'sharedApp/utils/login-local-storage.js';

export const DEFAULT_PROVIDERS = ['google', 'managebac-global', 'managebac-china'];
const loginAppTrackingStorage = new ClassicLoginLocalStorage(localStorage);

export default {
  name: 'LoginPageContainer',
  components: {
    LoginWarningBox,
    KogButton,
    KogInput,
    KogLoginErrorBox,
    SsoLogin,
  },
  data: () => ({
    email: '',
    password: '',
    passwordEnabled: false,
    passwordFieldType: 'password',
    warningCode: '',
    providerNames: DEFAULT_PROVIDERS,
    isLoadingPassword: false,
    isLoadingSSO: false,
    error: '',
    providersFetched: false,
    LOGIN_ERROR: 'LOGIN_ERROR',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  }),
  computed: {
    isPasswordShown() {
      return this.passwordFieldType === 'text';
    },
    validEmailSelectForm() {
      return this.email;
    },
    nextUrl() {
      return this.$route.query.next;
    },
  },
  watch: {
    email() {
      if (this.providersFetched) {
        this.providersFetched = false;
        this.password = '';
        this.providerNames = DEFAULT_PROVIDERS;
      }
    },
  },
  mounted() {
    this.initPageshowEventListener();
    const { error } = this.$route.query;
    if (!error && this.nextUrl) {
      this.warningCode = 'unauthenticated_user';
    } else if (error) {
      this.warningCode = error;
      this.clearErrorQueryParam();
    }
    this.$refs.emailInput.focus();

    loginAppTrackingStorage.removeFlag();
  },
  methods: {
    setEmailInputError() {
      this.$refs.emailInput.setValidationMessage('Please enter a valid email address.');
    },
    isError(errorCode) {
      return this.error === errorCode;
    },
    initPageshowEventListener() {
      window.addEventListener('pageshow', event => {
        if (event.persisted) {
          this.isLoadingPassword = false;
          this.isLoadingSSO = false;
        }
      });
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    clearEmail() {
      this.email = '';
      this.password = '';
      this.providersFetched = false;
      this.providerNames = DEFAULT_PROVIDERS;
      this.$refs.emailInput.focus();
    },
    async getProviders() {
      this.isLoadingPassword = true;
      try {
        const providers = await getAuthenticationProviders(this.email);

        const instantRedirectUrl = providers.instant_redirect_url;
        if (instantRedirectUrl) {
          this.redirectToSSO(instantRedirectUrl);
          return;
        }

        this.updateAvailableLoginOptions(providers);
        this.clearError();
        this.setPasswordFocus();
      } catch (error) {
        this.handleGetProvidersError(error);
      }
      this.isLoadingPassword = false;
    },
    updateAvailableLoginOptions(providers) {
      this.providersFetched = true;
      this.passwordEnabled = providers.password;
      this.providerNames = providers.sso_providers.map(provider => provider.provider);
    },
    handleGetProvidersError(error) {
      if (error.response && error.response.status === 400) {
        this.setEmailInputError();
      } else {
        this.error = this.UNKNOWN_ERROR;
      }
    },
    redirectToSSO(url) {
      const newUrl = new URL(url, window.location.href);
      if (this.nextUrl) {
        newUrl.searchParams.set('next', this.nextUrl);
        window.location.href = newUrl.toString();
      } else {
        window.location.href = newUrl.toString();
      }
    },
    async loginWithPassword() {
      this.isLoadingPassword = true;
      try {
        await this.handleLoginWithPassword();
      } catch (error) {
        this.isLoadingPassword = false;
        this.handlePasswordLoginError(error);
      }
    },
    async handleLoginWithPassword() {
      const nextUrl = this.$route.query.next;
      const response = await login(this.email, this.password, nextUrl);
      if (response && response.status === 200) {
        const isNextUrlSafe = response.data.is_next_url_safe === true;
        window.location = isNextUrlSafe ? this.nextUrl : '/';
      }
      this.clearError();
    },
    handlePasswordLoginError(error) {
      if (error.response && error.response.status === 401) {
        this.error = this.LOGIN_ERROR;
        this.password = '';
      } else {
        this.error = this.UNKNOWN_ERROR;
      }
    },
    loginSSO(provider) {
      this.isLoadingSSO = true;
      this.redirectToSSO(provider.url);
    },
    clearErrorQueryParam() {
      const queryParams = { ...this.$route.query };
      delete queryParams.error;
      this.$router.replace({ query: queryParams });
    },
    setPasswordFocus() {
      nextTick(() => {
        this.$refs.passwordInput.focus();
      });
    },
    clearError() {
      this.error = '';
      this.$refs.emailInput.clearValidationMessage();
    },
  },
};
</script>

<template>
  <div class="flexContainer flexContainer-center padd-top-xxl">
    <div class="LoginPageContainer">
      <login-warning-box
        v-if="warningCode"
        :warning-code="warningCode"
      />

      <div class="LoginCard padd-top-xl padd-left-xxl padd-right-xxl padd-bottom-xl">
        <h1 class="heading-s sm:heading-m margin-bottom-l"> Welcome back to Kognity! </h1>
        <form
          id="login-form"
          ref="emailSelectForm"
          novalidate
          @submit.prevent=""
        >
          <div role="alert">
            <kog-login-error-box v-if="isError(LOGIN_ERROR)">
              Sorry, either your email or password is incorrect. We can help you
              <router-link to="password-reset"> reset your password </router-link>
            </kog-login-error-box>
            <kog-login-error-box v-if="isError(UNKNOWN_ERROR)">
              <p>
                We could not log you in. Check your network connection or try again in a few
                minutes!
              </p>
              <p> If the issue persists, please contact our support team! </p>
            </kog-login-error-box>
          </div>
          <div class="margin-bottom-m">
            <kog-input
              ref="emailInput"
              v-model:value="email"
              data-test-id="email-input"
              label="Email"
              type="email"
              autocomplete="email"
              name="email"
              placeholder="Enter your email"
              validate-on="none"
              :input-icon="email ? 'times' : ''"
              icon-position="right"
              clickable-icon-label="Clear email"
              is-icon-clickable
              required
              @icon-click="clearEmail"
            />
          </div>

          <div
            v-if="providersFetched"
            class="margin-bottom-l"
          >
            <kog-input
              v-if="email"
              ref="passwordInput"
              v-model:value="password"
              data-test-id="password-input"
              label="Password"
              :type="passwordFieldType"
              autocomplete="current-password"
              name="password"
              placeholder="Enter your password"
              :input-icon="isPasswordShown ? 'eye-slash' : 'eye'"
              icon-position="right"
              clickable-icon-label="Show or hide password"
              is-icon-clickable
              @icon-click="togglePasswordVisibility"
            />
            <div class="margin-top-xs">
              <router-link
                to="password-reset"
                class="KogButtonLegacy--link"
              >
                Forgot password?
              </router-link>
            </div>
          </div>

          <kog-button
            v-if="!providersFetched"
            data-test-id="login-form-submit-button"
            type="submit"
            class="width-full"
            button-style="accent"
            tooltip="Continue"
            label="Continue"
            :is-loading="isLoadingPassword"
            :disabled="!validEmailSelectForm || isLoadingPassword || isLoadingSSO"
            aria-label="Continue"
            @click="getProviders"
          />
          <kog-button
            v-else
            data-test-id="login-form-submit-button"
            type="submit"
            class="width-full"
            button-style="accent"
            tooltip="Log in"
            label="Log in"
            :is-loading="isLoadingPassword"
            :disabled="!validEmailSelectForm || isLoadingPassword || isLoadingSSO || !password"
            aria-label="Log in"
            @click="loginWithPassword"
          />
        </form>

        <h2 class="LoginCard-sectionBreak margin-top-xl margin-bottom-xl divider-bottom">
          <span class="LoginCard-sectionBreak--text muted"> or log in with </span>
        </h2>
        <sso-login
          :provider-names="providerNames"
          :disabled="isLoadingPassword"
          @click="loginSSO"
        />
      </div>
      <div class="width-full flexContainer flexContainer-center margin-top-m">
        <router-link
          class="white-link"
          to="classic-login"
          >Use Classic Login</router-link
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.LoginPageContainer {
  max-width: 390px;
}

.LoginCard {
  width: 100%;

  font-family: var(--kog-satoshi);
  color: var(--kogPlatformGray018);

  background-color: var(--kogPlatformWhite);
  border: 1px solid var(--kogPlatformGray090);
  border-radius: var(--space-xs);
}

@media screen and (min-width: 576px) {
  .LoginCard {
    width: auto;
    min-width: 390px;
  }

  .sm\:heading-m {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
}

@media screen and (min-width: 768px) {
  .md\:flexChild-flexCenter {
    align-self: center;
  }
}

.LoginCard-inputActionIcon {
  position: absolute;
  top: 8px;
  right: 5px;

  display: inline-block;

  margin: 0;
}

.LoginCard-loginButton {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0;

  text-align: center;
}

.LoginCard-buttonLogo {
  width: 38px;
  height: 100%;
}

.LoginCard-buttonText {
  flex-grow: 1;
  text-align: center;
}

.LoginCard-sectionBreak {
  width: 100%;
  line-height: 0.1em;
  text-align: center;
}

.LoginCard-sectionBreak--text {
  padding: 0 var(--space-m);
  font-size: var(--kog-font-size-default);
  font-weight: normal;
  background-color: var(--kogPlatformWhite);
}

:focus {
  border-color: var(--kogPlatformBlueDarken20);
}

.white-link {
  color: var(--kogPlatformGray098);
}
</style>
